/**
 * (c) 智汇考题
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import utils from './js/utils';
import api from './js/request/request';
import Message from 'vue-m-message';
import {highlight} from 'vue-words-highlight';
import 'vue-m-message/dist/index.css';
import {format} from 'timeago.js';

import './assets/commen/variable.less';
import 'vant-green/lib/index.less'
import "@/js/directives";


Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$message = Message;
Vue.prototype.$utils = utils;
import ThumbBar from "@/components/thumb-bar";

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.auth === true) {
    let token = utils.getToken();
    if (!token) {
      next({
        name: "Login",
        query: {
          url: window.location.href
        }
      });
      return;
    }
  }
  next();
});
Vue.use(highlight, {
  name: "highlight",
  className: "blue",
  style: "color: #3CA7FA",
  caseSensitive: false,
});
Vue.filter("changeTime", function(dateStr) {
  return format(dateStr, "zh_CN");
});
Vue.component("ThumbBar", ThumbBar);
Vue.mixin({
  methods: {
    goBack() {
      if (window.history.length <= 2) {
        this.$router.push({ name: "Index" });
      } else {
        //this.$router.go(-1);
        console.log('back')
        this.$router.back();
      }
    },
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
